<template>
  <el-dialog :title="editData.isEdit?'编辑':editData.type?'新增子区域':'新增区域'" :visible.sync="_show" width="600px" >
    <el-form :model="editData" :rules="editRules" ref="reparirAreaEditForm" size="medium">
      <el-form-item label="区域" prop="areaName">
        <el-input maxlength="20" v-model.trim="editData.areaName" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="备注">
        <el-input maxlength="80" type="textarea" max v-model="editData.remark" placeholder="请输入"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
        <el-button @click="_show=false" size="medium">取消</el-button>
        <el-button  type="primary" @click="confirm" size="medium">确定</el-button>
      </span>
  </el-dialog>
</template>

<script>
import {validateSpeCharacter} from "@/utils/validator";
export default {
  name: "regional-setting-edit",
  props:{
    show:{
      type:Boolean,
      default(){
        return false
      }
    },
    editData:{
      type:Object,
      default() {
        return {}
      }
    }
  },
  computed:{
    _show:{
      get(){
        return this.show;
      },
      set(val){
        this.$emit("update:show",val)
      }
    }

  },
  data(){
    return {
      editRules:{
        areaName:[this.$formValidator.empty('区域名称','blur'),{validator:validateSpeCharacter,trigger: 'blur'}]
      }
    }
  },
  watch:{
    _show(newVal){
      if(newVal){
        this.$refs.reparirAreaEditForm.clearValidate()
      }
    }
  },
  methods:{
    confirm(){
      this.$refs.reparirAreaEditForm.validate(valid=>{
        if(valid){
          this._show = false;
          let handler = null;
          let param = {
            id:this.editData.id||'',
            remark:this.editData.remark,
            areaName:this.editData.areaName,
          };
          if(this.editData.isEdit){
            handler = this.$api.property.editRepairArea(param)
          }else{
            if(this.editData.type){
              handler = this.$api.property.addRepairChildArea(param)
            }else{
              delete param.id
              handler = this.$api.property.addRepairArea(param)
            }
          }
          handler.then(res=>{
            if(res.code==200){
              this.$message.success('操作成功')
              this._show  = false;
              this.$emit("refresh");
            }else{
              this.$message.error(res.msg)
            }
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>